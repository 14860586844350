<template>
  <v-container 
    style="
      padding: 0;
    "
  >
    <v-row>
      <v-navigation-drawer
        app
        fixed
        temporary
        v-model="drawer"
      >
        <v-list>
          <v-list-item :to="page.path" link v-for="page in pages" :key="page.id">
            <v-list-item-icon>
              <v-icon> {{page.icon}} </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{page.text}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar
        color='rgba(0,0,0,0)'
        flat
        dark
        app
        v-if="dark"
      >
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        
        <v-spacer></v-spacer>
        <v-btn 
          icon
          class="h3 ma-3"
          @click="toSignIn"
        >
          Sign In
        </v-btn>
      </v-app-bar>
      <v-app-bar
        height="83px"
        color='#E9E5DD'
        flat
        app
        v-else
      >
        <v-btn
          class="mx-7"
          color="#999999"
          icon
          @click="toHome"
        >
          <v-img 
            max-width="79px"
            src="../assets/CF_Logo.png"
          ></v-img>
        </v-btn>

        <v-divider
          class="mx-5"
          vertical
        ></v-divider>

        <v-app-bar-nav-icon
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>
        
        <v-spacer></v-spacer>
        <v-btn 
          icon
          class="h3 ma-3"
          @click="toSignIn"
          v-if="!user.email"
        >
          Sign In
        </v-btn>
        <v-btn 
          icon
          class="h3 ma-3"
          @click="signOut"
          v-if="user.email"
        >
          Sign Out
        </v-btn>
      </v-app-bar>
    </v-row>
    <v-row v-if="user.email">
      <v-col class="text-left pr-5" cols="6">
        <h2 class="title text-uppercase">{{ user.email }}</h2>
      </v-col>
      <v-col v-if="user.stripeAccountExists" @click="redirectToStripeAccountManagement()" class="text-right pr-5" cols="6">
        <v-btn class="ma-5">Manage Billing</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import router from '../router'
import { DB } from '../firebase/db'
import { Functions } from '../firebase/functions'

const createPortalSession = Functions.httpsCallable('createPortalSession')

export default {
  name: 'StatusBar',
  props: {
    showCompletedButton: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      drawer: false,
      pages: [
        {
          id: 0,
          path: '/',
          name: 'Home',
          icon: 'mdi-home',
          text: 'Home',
        },
        {
          id: 1,
          path: '/about',
          name: 'About',
          icon: 'mdi-home',
          text: 'About',
        },
        {
          id: 2,
          path: '/sign-in',
          name: 'SignIn',
          icon: 'mdi-home',
          text: 'Sign In',
        },
        {
          id: 3,
          path: '/contact-us',
          name: 'ContactUs',
          icon: 'mdi-home',
          text: 'Contact Us',
        }
      ]
    }
  },
  computed: {
    ...mapState(['user']),
    dark() {
      if(this.$route.name === 'Home') {
        return true
      } else {
        return false
      }
    },
    uid: function() {
      return this.$store.state.user.uid
    }
  },
  methods: {
    signOut() {
      this.$store.dispatch('signOut')
    },
    toSignIn() {
      if(this.$route.name !== 'SignIn') {
        router.push({ name: 'SignIn' })
      }
    },
    toHome() {
      if(this.$route.name !== 'Home') {
        router.push({ name: 'Home' })
      }
    },
    async redirectToStripeAccountManagement() {
      let customer = await DB.collection('user')
        .doc(this.uid)
        .collection('stripe')
        .doc('stripe_customer')
        .get()
        
      let session = await createPortalSession({
        customer: customer.data().stripe_customer_id
      })

      location.href = session.data.session.url
    }
  },
}
</script>

<style scoped>

</style>