import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import { Auth } from '../firebase/auth'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "sign-in" */ '../views/SignIn.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    // route level code-splitting
    // this generates a separate chunk (contact-us.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact-us" */ '../views/ContactUs.vue')
  },
  {
    path: '/get-started',
    name: 'GetStarted',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "get-started" */ '../views/GetStarted.vue'),
    beforeEnter: (to, from, next) => {
      if(!store.state.user.emailVerified) {
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/admin-landing',
    name: 'AdminLanding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-landing" */ '../views/AdminLanding.vue'),
    beforeEnter: (to, from, next) => {
      if(!store.state.user.isAdmin) {
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/tenant-landing',
    name: 'TenantLanding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tenant-landing" */ '../views/TenantLanding.vue'),
    beforeEnter: (to, from, next) => {
      if(store.state.user.isAdmin || !store.state.user.email) {
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/slp-agreement',
    name: 'SLPAgreement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "slp-agreement" */ '../views/SLPAgreement.vue'),
    beforeEnter: (to, from, next) => {
      if(store.state.user.isAdmin || !store.state.user.email) {
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/upload-customers',
    name: 'UploadCustomers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "upload-customers" */ '../views/UploadCustomers.vue'),
    beforeEnter: (to, from, next) => {
      if(store.state.user.isAdmin || !store.state.user.email) {
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/user-login',
    name: 'UserLogin',
    // Might not need this, taken care of by Home

    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "user-login" */ '../views/UserLogin.vue'),
    beforeEnter: (to, from, next) => {
      if(store.state.user.email) {
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/verification',
    name: 'SmtpVerification',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "verification" */ '../views/SmtpVerification.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
