<template>
  <v-container style="padding: 0" fluid>
    <status-bar></status-bar>

    <v-row>
      <v-col cols="12">
        <div id="background-1">
          <img class="header-image" src="../assets/Header Logo.png" />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div id="background-2">
          <div>
            <h2 class="sub-headings font">
              Stabilize your income <br />
              ups and downs.
            </h2>
            <p class="font text" :class="{'lr-padding' : !$vuetify.breakpoint.xsOnly}">
              Take control of cash flow with one simple marketing tool, then build your service business with the confidence of reliable income all year long. Even if work is seasonal or demand is sporadic, CertainFeeTM can help you stop the seesaw and steady your income, offering greater peace of mind and more freedom to flourish.
            </p>
            <v-btn @click="toSignIn" color="#97C657" class="button">
              Get Started!
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div id="background-3">
          <div>
            <h2 class="sub-headings font">
              Free your mind. Find your time.
            </h2>
            <div class="text" :class="{'lr-padding' : !$vuetify.breakpoint.xsOnly}">
              <p class="font">
                Hectic days can keep you working in your business, rather than on it. Even during slow stretches, worrying and scrambling to make ends meet can sabotage your efforts to gain real efficiency and growth.
              </p>
              <p class="font">
                CertainFee helps you do business better. Convert your existing customers to convenient, discounted level payments, and you’ll also establish all-season income for yourself.
              </p>
              <p class="font">
                Beyond greater control, steady cash flow contributes to a higher sense of confidence in the business you’ve created. That’s a base you can build on.
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    

    <v-row>
      <v-col cols="12">
        <div id="background-2">
          <div>
            <h2 class="sub-headings font">
              Build your own bridges.
            </h2>
            <div class="text-2 bridge-image" :class="{'lr-padding' : !$vuetify.breakpoint.xsOnly}">
              <div class="">
                <p class="font">
                  Why depend on loans (with substantial interest) or pay for outside contract services (at a premium) when you can bridge your income gaps with a level-pay offering that rewards loyal customers who already appreciate your service?
                </p>
                <p class="font">
                  Bonus: Satisfied customers deliver your best referrals. That’s how CertainFee also helps you create the new connections that ultimately build your business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div id="background-4">
          <div>
            <h2 class="sub-headings font">
              Help your clients help you grow.
            </h2>
            <div class="text" :class="{'lr-padding' : !$vuetify.breakpoint.xsOnly}">
              <p class="font">
                Reliable cash flow enables you to hire good employees and keep them on throughout the seasonal cycles. Good employees take good care of your customers—while your reputation and your brand grow stronger. And when it’s finally time for your exit strategy, an established monthly income helps attract potential buyers for your business.
              </p>
              <p class="font">
                CertainFee helps make it all happen, simply by offering your customers the option to pay for a discounted, baseline service in 12 equal monthly payments. They enjoy overall savings and convenient budgeting. You enjoy cash flow confidence.
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="3">
        <v-img
          class="img-margin"
          height="100%"
          src="../assets/Salon.jpg"
        ></v-img>
      </v-col>
      <v-col cols="3">
        <v-img
          class="img-margin"
          height="100%"
          src="../assets/Pest-Control-Man-2.jpg"
        ></v-img>
      </v-col>
      <v-col cols="3">
        <v-img
          class="img-margin"
          height="100%"
          src="../assets/Grooming.jpg"
        ></v-img>
      </v-col>
      <v-col cols="3">
        <v-img
          class="img-margin"
          height="100%"
          src="../assets/Piano.jpg"
        ></v-img>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div id="background-5">
          <div>
            <img class="header-image" src="../assets/Header Logo.png" />
            <h2 class="sub-headings font">
              Enjoy more certainty with CertainFee&#8482;.
            </h2>
            <ul class="text" :class="{'lr-padding' : !$vuetify.breakpoint.xsOnly}">
              <li class="ma-5">
                Choose a basic service you can offer your entire customer base at a nominal discount when they choose your convenient level-pay option.
              </li>
              <li class="ma-5">
                Log in to the CertainFee portal and use the quick-and-easy templates to customize your messages (e-mail, text, and letter) complete with your logo if available.
              </li>
              <li class="ma-5">
                Select a tier based on the number of customers you want to reach, then enter or upload your mailing list and other client information.
              </li>
              <li class="ma-5">
                Review your messages, check your list, and send!
              </li>
              <li class="ma-5">
                Add respondents to level-pay using your existing billing system—no need to change bookkeeping practices or accounting software—and start enjoying cash-flow confidence all year long.
              </li>
            </ul>
            <v-btn @click="toSignIn" color="#97C657" class="button-2">
              Get Started!
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <Footer></Footer>

  </v-container>
</template>

<script>
import StatusBar from '@/components/StatusBar.vue'
import Footer from '@/components/Footer.vue'

import { mapState } from 'vuex'
import router from '../router'

export default {
  name: 'Home',
  data() {
    return {}
  },
  components: {
    StatusBar,
    Footer,
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    loggedIn() {
      this.$store.dispatch('loggedIn')
    },
    toSignIn() {
      router.push({ name: 'SignIn' })
    }
  },
  mounted() {
    window.scrollTo(0,0)
    this.$store.dispatch('loggedIn')
  },
}
</script>

<style scoped>
.font {
  font-family: "NunitoSans-Light";
}

.button {
  margin-top: 43px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  color: whitesmoke;
}

.button-2 {
  margin-top: 43px;
  margin-bottom: 7vw;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: block;
  color: whitesmoke;
}

.lr-padding {
  padding-left: 20%;
  padding-right: 20%;
}

.text {
  padding-top: 43px;
  font-size: 12pt;
}

.text-2 {
  padding-top: 52px;
  padding-bottom: 43px;
  margin-top: -16px;
  font-size: 12pt;
}

.sub-headings {
  font-size: 27pt;
  line-height: 1em;
  text-align: center;
}

.header-image {
  width: 16vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8vw;
}

#background-1 {
  background: url('../assets/Header.jpg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-position: fixed !important;
   /* !important; */
  background-size: cover !important;
  height: 53vw;
  width: 100vw;
}

#background-2 {
   /* !important; */
  /* height: 41vw;
  width: 100vw; */
  padding: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #77787B;
}

#background-3 {
  background: url('../assets/Mowing.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-position: fixed !important;
   /* !important; */
  background-size: cover !important;
  /* height: 53vw;
  width: 100vw; */
  padding: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
}

#background-4 {
  background: url('../assets/Sod.jpg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-position: fixed !important;
   /* !important; */
  background-size: cover !important;
  padding: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
}

#background-5 {
   /* !important; */
  /* height: 58vw;
  width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #77787B;
}

.bridge-image {
  background: url('../assets/CF_Watermark.jpg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 47% !important;
}

.img-margin {
  margin: 8px 4px;
}
</style>
