<template>
  <v-app>

    <v-main style="padding: 0">
      <v-container 
        fluid
        style="padding: 0"  
      >
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!--  -->
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapState(['user']),
    currentRouteName() {
      return this.$route.name
    },
  },
  components: {},
  data() {
    return {
      drawer: false,
      pages: [
        {
          id: 0,
          path: '/',
          name: 'Home',
          icon: 'mdi-home',
        }
      ]
    }
  },
  methods: {}
}
</script>

<style>
.background {
  background: url('./assets/Header.jpg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-position: fixed !important;
   /* !important; */
  background-size: cover !important;
}
@font-face {
  font-family: 'NunitoSans-Bold';
  src: url("./assets/fonts/NunitoSans-Bold.ttf")
}
@font-face {
  font-family: 'NunitoSans-BoldItalic';
  src: url("./assets/fonts/NunitoSans-BoldItalic.ttf")
}
@font-face {
  font-family: 'NunitoSans-ExtraBold';
  src: url("./assets/fonts/NunitoSans-ExtraBold.ttf")
}
@font-face {
  font-family: 'NunitoSans-Light';
  src: url("./assets/fonts/NunitoSans-Light.ttf")
}
@font-face {
  font-family: 'NunitoSans-Regular';
  src: url("./assets/fonts/NunitoSans-Regular.ttf")
}
</style>