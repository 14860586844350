export default {
  config: {
 
    apiKey: "AIzaSyAfL2E7pi4ypBOZGjBT0Y6ybiikYVWume4",
  authDomain: "certainfree-632e7.firebaseapp.com",
  projectId: "certainfree-632e7",
  storageBucket: "certainfree-632e7.appspot.com",
  messagingSenderId: "471285453486",
  appId: "1:471285453486:web:f8b978add834353342933f",
  measurementId: "G-CMXBZWV2BS",
  databaseURL : "https://certainfree-632e7.firebaseio.com",
  },
}
