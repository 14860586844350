import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
// import Firebase from 'firebase/app'
import { Functions } from '../firebase/functions'
import { Auth } from '../firebase/auth'
import { DB } from '../firebase/db'
import _ from 'lodash'

const getStripeCustomer = Functions.httpsCallable('getStripeCustomer')
const recreateStripeCustomer = Functions.httpsCallable('recreateStripeCustomer')

// import _ from 'lodash'
// import http from '../http-common'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      emailVerified: false,
      photoURL: '',
      isAnonymous: false,
      uid: '',
      isAdmin: false,
      stripeAccountExists: false,
    },
    maxCustomers: undefined,
    stripeLoading: true,
    key: 0,
  },
  getters: {
    user: (state) => state.user 
  },
  mutations: {
    iterateKey(state) {
      state.key += 1
    },
    getCustomerData(state, customerData) {
      console.log('getCustomerData')
      state.stripeLoading = false
      state.maxCustomers = customerData.maxCustomers
      state.user.stripeAccountExists = customerData.stripeAccountExists
    },
    signOut(state) {
      state.user.firstName = ''
      state.user.lastName = ''
      state.user.userName = ''
      state.user.email = ''
      state.user.emailVerified = false
      state.user.photoURL = ''
      state.user.isAnonymous = false
      state.user.uid = ''
      if(router.currentRoute.name !== 'SignIn'){
        router.push({ name: 'SignIn' })
      }
    },
    loggedIn(state, userProfile) {
      state.user.firstName = userProfile.firstName
      state.user.lastName = userProfile.lastName
      state.user.userName = userProfile.userName
      state.user.email = userProfile.email
      state.user.emailVerified = Auth.currentUser.emailVerified
      state.user.photoURL = userProfile.photoURL
      state.user.isAnonymous = userProfile.isAnonymous
      state.user.uid = userProfile.uid
      state.user.isAdmin = userProfile.isAdmin
      if (state.user.isAdmin && router.currentRoute.name !== 'TenantLanding') {
        router.push({ name: 'AdminLanding' })
      } else if (state.user.email && router.currentRoute.name !== 'TenantLanding') {
        router.push({ name: 'TenantLanding' })
      }
    },
    registerAccount(state, userProfile) {
      state.user.firstName = userProfile.firstName
      state.user.lastName = userProfile.lastName
      state.user.userName = userProfile.userName
      state.user.email = userProfile.email
      state.user.emailVerified = Auth.currentUser.emailVerified
      state.user.photoURL = userProfile.photoURL
      state.user.isAnonymous = userProfile.isAnonymous
      state.user.uid = userProfile.uid
      state.user.isAdmin = userProfile.isAdmin
      if(router.currentRoute.name !== 'TenantLanding') {
        router.push({ name: 'TenantLanding' })
      }
    },
    loginAccount(state, userProfile) {
      state.user.firstName = userProfile.firstName
      state.user.lastName = userProfile.lastName
      state.user.userName = userProfile.userName
      state.user.email = userProfile.email
      state.user.emailVerified = Auth.currentUser.emailVerified
      state.user.photoURL = userProfile.photoURL
      state.user.isAnonymous = userProfile.isAnonymous
      state.user.uid = userProfile.uid
      state.user.isAdmin = userProfile.isAdmin
      if(router.currentRoute.name !== 'AdminLanding' || router.currentRoute.name !== 'TenantLanding'){
        if (userProfile.isAdmin) {
          router.push({ name: 'AdminLanding' })
        } else {
          router.push({ name: 'TenantLanding' })
        }
      }
    },
  },
  actions: {
    signOut({ commit }) {
      Auth.signOut()
        .then(function() {
          commit('signOut')
        })
        .catch(function(err) {
          alert(err.message)
        })
    },
    recreateStripeCustomer: ({ commit }, payload) => {
      recreateStripeCustomer(payload).then((customer) => {
        let stripeAccountExists = false
        let maxCustomers = 0
        let subscriptions = customer.data.subscriptions ? customer.data.subscriptions.data : undefined
        _.forEach(subscriptions, (subscription) => {
          // The Second subscription.plan.id check it for previous subscription prices to not break currently subscribed users
          if ((subscription.plan.id === 'price_1LpZZ9D7TGVtVh1bzVkMRNdT' || subscription.plan.id === 'price_1JOpVpD7TGVtVh1baI2UpCN6' || subscription.plan.id === 'price_1LpZbYD7TGVtVh1bz0kbOAxY') && subscription.plan.active && maxCustomers < 250) {
            maxCustomers = maxCustomers > 100 ? maxCustomers : 100
            stripeAccountExists = true
          } else if ((subscription.plan.id === 'price_1LqDcPD7TGVtVh1bfA4p80tj' || subscription.plan.id === 'price_1LqDcPD7TGVtVh1bEbCyO4wv') && subscription.plan.active && maxCustomers < 500) {
            maxCustomers = maxCustomers > 250 ? maxCustomers : 250
            stripeAccountExists = true
          } else if ((subscription.plan.id === 'price_1LqDinD7TGVtVh1bAEZBh5SO' || subscription.plan.id === 'price_1LqDj7D7TGVtVh1b49zbJFzl' || subscription.plan.id === 'price_1JOpX8D7TGVtVh1bHfm2p6kX') && subscription.plan.active && maxCustomers < 750) {
            maxCustomers = maxCustomers > 500 ? maxCustomers : 500
            stripeAccountExists = true
          } else if ((subscription.plan.id === 'price_1LqDkZD7TGVtVh1bO5sLies2' || subscription.plan.id === 'price_1LqDklD7TGVtVh1bTHYOfJpA') && subscription.plan.active && maxCustomers < 750) {
            maxCustomers = maxCustomers > 750 ? maxCustomers : 750
            stripeAccountExists = true
          } else if ((subscription.plan.id === 'price_1LqDlpD7TGVtVh1bnL0Bdn0o' || subscription.plan.id === 'price_1LqDm3D7TGVtVh1bcQKQFdQd') && subscription.plan.active && maxCustomers < 1000) {
            maxCustomers = maxCustomers > 1000 ? maxCustomers : 1000
            stripeAccountExists = true
          } else if ((subscription.plan.id === 'price_1Mb4XGD7TGVtVh1bRdiOVhqq') && subscription.plan.active && maxCustomers < 5) {
            maxCustomers = maxCustomers > 5 ? maxCustomers : 5
            stripeAccountExists = true
          } else {
            maxCustomers = maxCustomers > 0 ? maxCustomers : 0
          }
        })
        let customerData = {
          maxCustomers: maxCustomers,
          stripeAccountExists: stripeAccountExists,
        }
        commit('getCustomerData', customerData)

      })
    },
    getCustomerData: ({ commit }, payload) => {
      getStripeCustomer(payload).then((customer) => {
        let stripeAccountExists = false
        let maxCustomers = 0
        let subscriptions = customer.data.subscriptions ? customer.data.subscriptions.data : undefined
        _.forEach(subscriptions, (subscription) => {
          // The Second subscription.plan.id check it for previous subscription prices to not break currently subscribed users
          if ((subscription.plan.id === 'price_1LpZZ9D7TGVtVh1bzVkMRNdT' || subscription.plan.id === 'price_1JOpVpD7TGVtVh1baI2UpCN6' || subscription.plan.id === 'price_1LpZbYD7TGVtVh1bz0kbOAxY') && subscription.plan.active && maxCustomers < 250) {
            maxCustomers = maxCustomers > 100 ? maxCustomers : 100
            stripeAccountExists = true
          } else if ((subscription.plan.id === 'price_1LqDcPD7TGVtVh1bfA4p80tj' || subscription.plan.id === 'price_1LqDcPD7TGVtVh1bEbCyO4wv') && subscription.plan.active && maxCustomers < 500) {
            maxCustomers = maxCustomers > 250 ? maxCustomers : 250
            stripeAccountExists = true
          } else if ((subscription.plan.id === 'price_1LqDinD7TGVtVh1bAEZBh5SO' || subscription.plan.id === 'price_1LqDj7D7TGVtVh1b49zbJFzl' || subscription.plan.id === 'price_1JOpX8D7TGVtVh1bHfm2p6kX') && subscription.plan.active && maxCustomers < 750) {
            maxCustomers = maxCustomers > 500 ? maxCustomers : 500
            console.log('maxCustomers:', maxCustomers)
            stripeAccountExists = true
          } else if ((subscription.plan.id === 'price_1LqDkZD7TGVtVh1bO5sLies2' || subscription.plan.id === 'price_1LqDklD7TGVtVh1bTHYOfJpA') && subscription.plan.active && maxCustomers < 750) {
            maxCustomers = maxCustomers > 750 ? maxCustomers : 750
            stripeAccountExists = true
          } else if ((subscription.plan.id === 'price_1LqDlpD7TGVtVh1bnL0Bdn0o' || subscription.plan.id === 'price_1LqDm3D7TGVtVh1bcQKQFdQd') && subscription.plan.active && maxCustomers < 1000) {
            maxCustomers = maxCustomers > 1000 ? maxCustomers : 1000
            stripeAccountExists = true
          } else if ((subscription.plan.id === 'price_1Mb4XGD7TGVtVh1bRdiOVhqq') && subscription.plan.active && maxCustomers < 5) {
            maxCustomers = maxCustomers > 5 ? maxCustomers : 5
            stripeAccountExists = true
          } else {
            maxCustomers = maxCustomers > 0 ? maxCustomers : 0
          }
        })
        let customerData = {
          maxCustomers: maxCustomers,
          stripeAccountExists: stripeAccountExists,
        }
        commit('getCustomerData', customerData)
      })
    },
    loggedIn: async ({ commit, dispatch }) => {
      Auth.onAuthStateChanged((user) => {
        console.log('Vuex loggedIn')
        if (user) {
          user.getIdTokenResult().then(idTokenResult => {
            let userDocRef = DB.collection('user').doc(user.uid)
            let stripeDocRef = DB.collection('user').doc(user.uid).collection('stripe').doc('stripe_customer')

            userDocRef.get().then((user) => {
              stripeDocRef.get().then((stripeInfo) => {
                let stripeCustomerId = stripeInfo.data().stripe_customer_id
                  dispatch('getCustomerData', {
                    stripeCustomerId: stripeCustomerId
                  })
                
              }).catch(() => {
                console.log('catch')
                dispatch('recreateStripeCustomer', user)
              })
              user = user.data()
              user.isAdmin = idTokenResult.claims.admin
              commit('loggedIn', user)
            })
          })
        }
      })
    },
    registerAccount({ commit }, payload) {
      Auth.createUserWithEmailAndPassword(payload.email, payload.password)
        .then((user) => {
          let userProfile = {
            firstName: payload.firstName,
            lastName: payload.lastName,
            userName: payload.userName,
            email: payload.email,
            uid: user.user.uid,
            emailVerified: user.user.emailVerified,
            photoURL: user.user.photoURL,
            isAnonymous: user.user.isAnonymous,
          }
          DB.collection('user')
            .doc(user.user.uid)
            .set({
              firstName: userProfile.firstName,
              lastName: userProfile.lastName,
              userName: userProfile.userName,
              email: userProfile.email,
              uid: userProfile.uid,
              emailVerified: userProfile.emailVerified,
              photoURL: userProfile.photoURL,
              isAnonymous: userProfile.isAnonymous,
            })
            .then(function() {
              console.log('Document successfully written!')
            })
            .catch(function(error) {
              console.error('Error writing document: hello', error)
            })
          commit('registerAccount', userProfile)
        })
        .catch(function(err) {
          alert(err.message)
        })
    },
    sendEmailVerification() {
      let user = Auth.currentUser
      user.sendEmailVerification()
        .then(function() {
          alert('Verification Email Sent')
        })
        .catch(function(error) {
          alert(error)
        })
    },
    loginAccount({ commit }, payload) {
      Auth.signInWithEmailAndPassword(payload.email, payload.password).then(
        (user) => {
          let uid = user.user.uid
          user.user.getIdTokenResult().then(idTokenResult => {
            DB.collection('user')
              .doc(uid)
              .get()
              .then((user) => {
                if ('idTokenResult.claims.admin && !user.isAdmin: ', idTokenResult.claims.admin && !user.isAdmin) {
                  DB.collection('user')
                    .doc(uid)
                    .set({isAdmin: true}, {merge: true})
                }
                user = user.data()
                let userProfile = {
                  firstName: user.firstName,
                  lastName: user.lastName,
                  userName: user.userName,
                  email: user.email,
                  uid: user.uid,
                  emailVerified: user.emailVerified,
                  photoURL: user.photoURL,
                  isAnonymous: user.isAnonymous,
                  isAdmin: idTokenResult.claims.admin,
                }
                commit('loginAccount', userProfile)
              })
            })
        })
        .catch(function(err) {
          alert(err.message)
        })
    },
    iterateKey({ commit }) {
      commit('iterateKey')
    }
  },
  modules: {
  }
})
